<template>
  <div class="row px-3 align-center py-2">
    <div class="col-sm-12 col-md-4 pb-0 pb-md-2">
      <div class="d-flex align-center">
        <v-avatar size="60">
          <img
            :src="addResourceImage(conversation.receiver_image)"
            class="border border-4"
            @error="imageFallback($event)"
          />
        </v-avatar>
        <div class="ml-3 text-primary">
          <h5>{{ conversation.receiver_name }}</h5>
          <div>{{ conversation.latest_message_time }}</div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-8">
      <div class="d-flex align-center">
        <div>
          <h4>
            <router-link
              :to="`/user/product-query/` + conversation.slug"
              class="text-dark text-primary"
              style="color: #85EEDF !important"
            >
              {{ conversation.title }}
              <span
                v-if="conversation.sender_viewed == 0"
                class="custom-new-badge text-primary"
                
              >
                ({{ $t('new') }})
              </span>
            </router-link>
          </h4>
          <div v-if="conversation.latest_message.type">
            <img :src="addResourceImage(conversation.latest_message.message)" @error="imageFallback($event)" style="max-height: 50px;" />
          </div>
          <div v-else style="word-break: break-all; opacity: 0.7" class="text-primary">{{ conversation.latest_message.message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    conversation: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    linkify(text) {
      // 正则表达式匹配各种形式的 URL
      const urlPattern = /(\b(https?:\/\/|www\.|[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b)(\/[-a-zA-Z0-9@:%._+~#=]*)?)/gi;
      // 替换匹配到的 URL 为带 <a> 标签的链接
      return text.replace(urlPattern, function (url) {
        let href = url;
        if (!href.match(/^(https?:\/\/|www\.)/)) {
          href = 'https://' + href;
        }
        return `<a style="color: cornflowerblue" href="${href}" target="_blank">${url}</a>`;
      });
    },
  }
}
</script>
